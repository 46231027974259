import { HttpClient } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UploadResult } from 'src/app/services/api/top-level-file-route-api';
import { CreateResult } from 'src/app/services/api/top-level-route-api';
import { SelectOption } from 'src/app/settings/settings.model';
import { environment } from 'src/environments/environment';

@Component({ templateUrl: './upload-data-file-dialog.component.html' })
export class UploadDataFileDialog {
    form = new FormGroup({
        token: new FormControl('', Validators.required),
        fileType: new FormControl('', Validators.required),
        useV2: new FormControl(true, Validators.required),
    });
    file: File = null;
    fileTypeOptions: SelectOption[] = [
        { name: 'debug', value: 'debug', icon: 'bug' },
        { name: 'dmesg', value: 'dmesg', icon: 'receipt-outline' },
        { name: 'event', value: 'event', icon: 'alert-decagram' },
        { name: 'gps', value: 'gps', icon: 'earth' },
        { name: 'journal', value: 'journal', icon: 'script-text-outline' },
        { name: 'log', value: 'log', icon: 'chart-line-variant' },
        { name: 'txt', value: 'txt', icon: 'file-document' },
    ];

    get isUploadValid() {
        return this.form.valid && !!this.file;
    }

    constructor(
        public dialogRef: MatDialogRef<UploadDataFileDialog>,
        @Inject(MAT_DIALOG_DATA) public data: { deviceId: number },
        private http: HttpClient,
    ) {}

    onFileChange(event: Event) {
        const target = event.target as HTMLInputElement;
        if (!target.files || target.files.length == 0) {
            return;
        }
        this.file = target.files[0];
    }

    async upload() {
        const headers = {
            'x-access-token': this.form.controls.token.value,
        };
        if (this.form.controls.useV2.value) {
            await this.uploadV2(headers);
        } else {
            await this.uploadV1(headers);
        }
    }

    async uploadV1(headers): Promise<void> {
        const baseUrl =
            environment.apiUrl + '/api/v1/deviceUser/read-only/saveLogFile';
        const formData: FormData = new FormData();
        formData.append('uploadType', this.form.controls.fileType.value);
        formData.append('deviceId', this.data.deviceId.toString());
        formData.append('files', this.file);

        const uploadResult = (await this.http
            .post(baseUrl, formData, { headers })
            .toPromise()) as UploadResult;

        if (uploadResult.success) {
            this.dialogRef.close(true);
        } else {
            this.dialogRef.close(false);
        }
    }

    async uploadV2(headers): Promise<void> {
        const baseUrl = environment.apiUrl + '/api/v2/data-files';
        const createResult = (await this.http
            .post(
                baseUrl,
                { type: this.form.controls.fileType.value },
                { headers },
            )
            .toPromise()) as CreateResult;
        if (createResult.success) {
            const formData: FormData = new FormData();
            formData.append('files', this.file);
            const uploadResult = (await this.http
                .post(baseUrl + '/' + createResult.id + '/file', formData, {
                    headers,
                })
                .toPromise()) as UploadResult;
            if (uploadResult.success) {
                this.dialogRef.close(true);
            } else {
                this.dialogRef.close(false);
            }
        } else {
            this.dialogRef.close(false);
        }
    }
}
