<h1 mat-dialog-title>{{ 'simulator.data_file.upload' | translate }}</h1>
<div mat-dialog-content class="mat-typography">
    <div [formGroup]="form" class="flex flex-col gap-2">
        <div>
            <div class="mat-subtitle-2">{{ 'device.token' | translate }}</div>
            <sz-input
                formControlName="token"
                errorMessage="form.field_required"
                [showError]="form.controls.token.invalid"
            />
        </div>
        <div>
            <div class="mat-subtitle-2">
                {{ 'data_files.file_type' | translate }}
            </div>
            <sz-select formControlName="fileType" [options]="fileTypeOptions" />
        </div>
        <div class="flex flex-row items-center justify-between">
            <div class="truncate">
                {{ file?.name ?? 'select.selected.none' | translate }}
            </div>
            <sz-icon-button
                icon="upload"
                size="large"
                (click)="fileInput.click()"
            />
        </div>
        <div class="flex flex-row justify-between">
            <div class="mat-subtitle-2">
                {{ 'simulator.data_file.upload.useV2' | translate }}
            </div>
            <sz-toggle-button class="mx-3" formControlName="useV2" />
        </div>
    </div>
    <sz-dialog-button-bar
        primaryText="btn.upload"
        [primaryDisabled]="!isUploadValid"
        (primaryClick)="upload()"
        (secondaryClick)="dialogRef.close()"
    />
</div>

<input
    #fileInput
    name="files"
    hidden
    type="file"
    (change)="onFileChange($event)"
/>
